














































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import JwlButton from '@/components/JwlButton.vue';
import { ExtendedRunTask } from '@/store/interface/Task';
import { AttendanceGrade, ScoreGrade } from '@/store/interface/Grade';

const STATUS_NOTHING = 0;
const STATUS_SUBMITTING = 1;
const STATUS_SUCCESS = 2;
const STATUS_ERROR = -1;

@Component({
  components: {
    JwlButton,
  },
})
export default class JwlDiscussionGradingForm extends Vue {
  $refs!: {
    studentGradeForm: HTMLFormElement;
  }

  @Prop(Object)
  jwlGrade!: ScoreGrade | AttendanceGrade | null;

  @Prop(String)
  lmsId!: string;

  @Prop(Object)
  jwlTask!: ExtendedRunTask;

  submitStatus = STATUS_NOTHING;
  score = 0;
  comment = '';
  grade: ScoreGrade | AttendanceGrade | null = null;
  error = null;

  submitGrade (andPublish = false): void {
    if (this.submitStatus !== STATUS_SUBMITTING) {
      this.submitStatus = STATUS_SUBMITTING;
      const FD = new FormData(this.$refs.studentGradeForm);
      const courseCode = this.$route.params.course;
      const taskCode = this.$route.params.task;
      const { lmsId } = this;

      if (andPublish) {
        FD.append('publish', 'true');
      }

      this.$store.dispatch('postData', {
        url: `gradebook/${courseCode}/task/${taskCode}/${lmsId}`,
        formData: FD,
      }).then((data) => {
        this.submitStatus = STATUS_SUCCESS;
        this.$emit('grade-updated', data);
        setTimeout(() => {
          this.submitStatus = STATUS_NOTHING;
        }, 2500);
      }).catch((e) => {
        this.error = e;
        this.submitStatus = STATUS_ERROR;
      });
    }
  }

  @Watch('jwlGrade')
  updateData (): void {
    if (this.jwlGrade) {
      this.comment = this.jwlGrade.comment || '';
      this.score = this.jwlGrade.score || 0;
      this.grade = this.jwlGrade;
    }
  }

  get scores (): number[] {
    const options: number[] = [];
    for (let option = 0; option <= this.jwlTask.maxScore; option += 1) {
      if (this.jwlTask.halfScore && option > 0) {
        options.push(option + 0.5);
      }
      options.push(option);
    }
    return options;
  }

  created (): void {
    this.updateData();
  }
}
