<template lang="pug">
  .jwl-student-switch.jwl-sticky
    .jwl-student-switch__container.jwl-sticky__container(:class="mobileOpenClass")
      .jwl-student-switch__mobile-open-toggle(@click="openMobile")
        span(v-if="mobileOpen")
          | Close Student Selection
        span(v-else)
          | Open Student Selection
      router-link.jwl-student-switch__student.jwl-sticky__content(
        v-for="data in grades"
        :key="`jwl-grading--${data.id}`"
        :to="{ name: 'grade', params: { lmsId: data.id } }"
        :class="[activeGradeClass(data.id), gradeSubmissionClass(data)]")
        .jwl-student-switch__header
          .jwl-student-switch__student-name
            span
              | {{data.displayName}}
            .jwl-student-switch__student-id
              | ({{$t('general.lmsId', { lmsId: data.id })}})
</template>

<script>
import jwlDiscussionGradingForm from './jwlDiscussionGradingForm.vue';

export default {
  name: 'jwl-student-switch',
  components: {
    jwlDiscussionGradingForm,
  },
  props: {
    jwlTask: Object,
  },
  data () {
    return {
      grades: null,
      mobileOpen: false,
      loading: true,
    };
  },
  methods: {
    getData () {
      this.$store.dispatch('getData', `gradebook/${this.$route.params.course}/get-students/${this.$route.params.task}`)
        .then((data) => {
          this.grades = data;
          this.loading = false;
        });
    },
    activeGradeClass (lmsId) {
      return this.$route.params.lmsId === lmsId
        ? 'jwl-student-switch__student--open'
        : '';
    },
    localizedDate (dateString) {
      const date = new Date(dateString);
      return date.toUTCString();
    },
    gradeSubmissionClass (data) {
      const baseClass = 'jwl-student-switch__student';
      const { submitted } = data;

      if (submitted && submitted.state !== 'nothing') {
        if (submitted.grade) {
          if (submitted.grade === 'graded') {
            if (this.jwlTask.maxScore > 0 && submitted.grade.score === 0) {
              return `${baseClass}--0-grade`;
            }
            return `${baseClass}--grade`;
          }
          return `${baseClass}--unpublished`;
        }
        return `${baseClass}--ungraded`;
      }
      return `${baseClass}--nothing`;
    },
    updateGrade (data) {
      const index = this.grades.findIndex((grade) => grade.student.id === data.student);
      if (index >= 0) {
        this.grades[index].id = data.id;
        this.grades[index].grade = data.grade;
      }
    },
    openMobile () {
      this.mobileOpen = !this.mobileOpen;
    },
  },
  computed: {
    mobileOpenClass () {
      return this.mobileOpen
        ? 'jwl-student-switch__container--open'
        : '';
    },
  },
  mounted () {
    this.getData();
  },
};
</script>

<style lang="scss">
@use "~common-modules/src/styles/mixins";
@use "~common-modules/src/styles/variables";

@import "~common-modules/src/styles/sticky";

.jwl-student-switch {
  $root: &;

  @include mixins.ltrtl(margin-right, margin-left, 1rem);

  display: flex;
  flex: 0 1 15rem;
  flex-flow: column;

  + .jwl-submit-task__grade-container,
  + .jwl-grade__grade-container {
    flex: 0 1 50rem;
    margin: 0 0 2rem auto;

    @include mixins.mq(variables.$mq-tablet) {
      margin: 0 auto 2rem;
    }
  }

  &__container {
    height: calc(100vh - var(--nav-bar-height));
    overflow: auto;
    position: sticky;
    top: var(--nav-bar-height);
    z-index: 10;

    @include mixins.mq(variables.$mq-tablet) {
      bottom: 0;
      box-sizing: border-box;
      height: 3.5rem;
      left: 0;
      position: fixed;
      top: unset;
      width: 100vw;

      #{$root}__student {
        display: none;
      }

      &--open {
        background-color: var(--color-white);
        height: calc(100vh - var(--container-top));
        padding: 1rem 0;

        #{$root}__student {
          display: flex;
        }

        #{$root}__mobile-open-toggle {
          margin-bottom: 1rem;
        }
      }
    }
  }

  &__mobile-open-toggle {
    display: none;

    @include mixins.mq(variables.$mq-tablet) {
      align-items: center;
      background-color: var(--color-gray-100);
      border-top: .1rem solid var(--color-grey);
      box-sizing: border-box;
      display: flex;
      height: variables.$footer-height;
      justify-content: center;
      width: 100%;
    }
  }

  &__student {
    --bg-color: var(--concentration-opaque);
    --open-bg: var(--color-white);
    --open-border: var(--bg-color);
    --open-text: var(--color-grey);
    --text-color: var(--concentration-color);

    align-items: flex-start;
    background-color: var(--bg-color);
    border: .1rem solid var(--bg-color);
    border-radius: .2rem;
    box-sizing: border-box;
    color: var(--text-color);
    display: flex;
    flex-flow: column;
    margin: 0 auto 1rem;
    padding: .75rem 1rem;
    transition: background-color .25s, color .25s;
    width: 95%;

    &--nothing {
      --bg-color: var(--color-gray-100);
      --text-color: var(--color-grey);
    }

    &--ungraded {
      --bg-color: var(--color-grey);
      --text-color: var(--color-white);
    }

    &--unpublished {
      --bg-color: var(--light-warning);
      --text-color: var(--color-warning);
    }

    &--0-grade {
      --bg-color: var(--light-error);
      --open-bg: var(--bg-color);
      --open-border: var(--bg-color);
      --open-text: var(--text-color);
      --text-color: var(--color-error);
    }

    &--grade {
      --bg-color: var(--light-success);
      --open-bg: var(--bg-color);
      --open-border: var(--bg-color);
      --open-text: var(--text-color);
      --text-color: var(--color-success);
    }

    &--open {
      background-color: var(--open-bg);
      border-color: var(--open-border);
      color: var(--open-text);

      #{$root}__toggle {
        &::before {
          transform: rotate(-45deg);
        }

        &::after {
          transform: rotate(45deg);
        }
      }

      #{$root}__grade {
        display: flex;
      }
    }
  }

  &__header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &__student-name {
    align-items: baseline;
    display: flex;
    flex: 0 0 calc(100% - 1.5rem); // - toggle
    flex-flow: row wrap;
    justify-content: flex-start;
    width: calc(100% - 1.5rem);

    span {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__student-id {
    @include mixins.ltrtl(margin-left, margin-right, .5rem);

    display: flex;
    font-size: .85rem;
    opacity: .6;
  }
}
</style>
